<template>
  <v-app full-height>
    <AppHeader />
    <BaseMain :preparatory-padding="APP_HEADER_HEIGHT">
      <slot />
      <AppFooter />
    </BaseMain>
    <AppFixedFooter />
  </v-app>
</template>

<script lang="ts">
import { APP_HEADER_HEIGHT } from '~/constants'

export default defineComponent({
  name: 'DefaultLayout',
  setup() {
    return {
      APP_HEADER_HEIGHT
    }
  }
})
</script>
