<template>
  <v-app-bar
    class="d-none d-md-flex"
    tag="div"
    location="bottom"
    color="primary"
    style="z-index: 1006/*Исправление Hydration style mismatch https://github.com/vuetifyjs/vuetify/issues/19015*/"
    :height="height"
    flat
  >
    <v-container class="d-flex align-center justify-space-between">
      <p class="text-h6">Обменивайтесь данными с вашими партнёрами легко и безопасно</p>
      <v-btn
        class="bg-ui-kit-bg-gray-0"
        variant="text"
        color="primary"
        @click="handleShowContactForm"
      >
        Подключиться
      </v-btn>
    </v-container>
  </v-app-bar>

  <ClientOnly>
    <ContactFormDialog
      ref="contactFormDialog"
      @send="handleShowContactFormMessage(ContactFormDialogMessageType.Success)"
      @error:limit="handleShowContactFormMessage(ContactFormDialogMessageType.Error)"
    />
    <ContactFormDialogMessage ref="contactFormDialogMessage" />
  </ClientOnly>
</template>

<script lang="ts">
import { useDisplay } from 'vuetify'
import { ContactFormDialogMessageType, ContactFormRequest } from '~/enums'

export const APP_FIXED_FOOTER_HEIGHT = 56

export default defineComponent({
  name: 'AppFixedFooter',
  setup() {
    const contactFormDialog = ref<{ show:(type: ContactFormRequest) => void } | null>(null)
    const contactFormDialogMessage = ref<{ show:(type: ContactFormDialogMessageType) => void } | null>(null)
    const isMounted = useMounted()
    const display = useDisplay()
    const height = computed(() => {
      if (!isMounted.value) {
        return APP_FIXED_FOOTER_HEIGHT
      }

      return display.mdAndUp.value ? APP_FIXED_FOOTER_HEIGHT : 0
    })

    function handleShowContactForm() {
      contactFormDialog.value?.show(ContactFormRequest.ConnectMedflex)
    }

    function handleShowContactFormMessage(type: ContactFormDialogMessageType) {
      contactFormDialogMessage.value?.show(type)
    }

    return {
      height,
      ContactFormDialogMessageType,
      contactFormDialog,
      contactFormDialogMessage,
      handleShowContactForm,
      handleShowContactFormMessage
    }
  }
})
</script>
