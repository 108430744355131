<template>
  <v-col class="d-flex flex-column">
    <slot name="logo">
      <p class="text-subtitle-1 text-ui-kit-text">
        <slot name="title">{{ title }}</slot>
      </p>
    </slot>
    <div class="mt-4 d-flex flex-column">
      <div
        v-for="(link, index) in links"
        :key="link.linkText"
        class="text-body-1 text-ui-kit-text"
        :class="{
          'mt-6 mt-md-3': index !== 0,
        }"
      >
        <template v-if="link.prg">
          <PrgLink
            text-class="text-underline"
            :url="link.href"
            :text="link.linkText"
          />
        </template>
        <template v-else>
          <span
            v-if="link.text"
            class="text-decoration-none mr-1"
          >
            {{ link.text }}
          </span>
          <NuxtLink
            class="text-body-1 text-decoration-underline text-ui-kit-text"
            :href="link.href"
            :to="link.to"
          >
            {{ link.linkText }}
          </NuxtLink>
        </template>
      </div>
    </div>
    <template v-if="$slots.bottom">
      <v-spacer
        class="mt-auto"
      />
      <slot name="bottom" />
    </template>
  </v-col>
</template>

<script lang="ts">

import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

export type AppFooterColLink = {
  linkText: string
  text?: string
  to?: RouteLocationRaw,
  href: string
  prg?: boolean
}

export default defineComponent({
  name: 'AppFooterCol',
  props: {
    links: {
      type: Array as PropType<AppFooterColLink[]>,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  }
})
</script>
